<template>
  <div>
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="{}"
      @line-field-input="checkEvalInput(...arguments)"
      :headerTitle="true"
      :addable="true"
      :addButtTitle="'Add Attribute'"
      :value="localValue"
    ></lineFieldsArray>
  </div>
</template>

<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import options from "@/cfg/options.json";

export default {
  components: {
    lineFieldsArray,
  },
  props: ["result", "field", "fieldAttributes","value"],
  computed: {
    localValue() {
      let value = [];
      if (typeof this.value != "undefined") {
        value = this.value.eval;
      }
      return value;
    },

    fields() {
      let fields = {
        type: {
          type: "select",
          name: "Type",
          inputFieldFormatter: "gcEvalType",
        },
        regex: {
          type: "text",
          name: "Regular Expression",
          inputFieldFormatter: "gcEvalRegex",
        },
        operator: {
          type: "select",
          name: "Operator",
          associatedOption: options.operators,
        },
        content: {
          type: "text",
          name: "Content",
        },
        result: {
          type: "select",
          name: "Result",
          associatedOption: options.result,
          default: "error",
        },
        action: {
          type: "select",
          name: "Action",
          associatedOption: options.evalAction,
          default: "stopTest",
        },
        remove: {
          inputFieldFormatter: "trash",
          colAttrs: {
            style: "max-width: 23px;min-width: 35px;",
            class: "mt-1",
          },
        },
      };
      return fields;
    },
  },
  methods: {
    checkEvalInput(value) {
      // let evalValue = this.result.valueLine.res;
      console.log(value);
      value.forEach((element, index) => {
        if (element.type == "output") {
          this.$delete(value[index], "regex");
        }
      });
      let evalValue = this.value;
      if (typeof evalValue == "undefined") {
        evalValue = {};
      }
      this.$set(evalValue, "eval", value);
      this.$emit("input", evalValue);
      console.log(evalValue);
    },
  },
};
</script>
